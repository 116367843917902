.footer {
  border: solid black;
  background-color: black;
  color: white;
  display: flex;
  padding: 8rem 1rem 8rem 1rem;
  justify-content: space-around;
}

.mailIcon {
  border: solid gray;
}
.linkedin:hover,
.instagram:hover,
.github:hover,
.facebook:hover,
.envelope:hover {
  opacity: 0.6;
  cursor: pointer;
}

.linkedin,
.instagram,
.github,
.facebook,
.envelope {
  margin: 0.82rem;
}

a {
  text-decoration: none;
  color: white;
}

.bottomText {
  background-color: black;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.8rem;
  padding-bottom: 0.6rem;
}
