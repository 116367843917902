
.heading{
    border-top: solid var(--outlines);
    border-width: 0.5px;
    width: 100%;
    text-align: left;
    max-width: 80rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    animation:  fade-in ease-in 0.5s forwards;
    animation-delay: 1s;
    opacity: 0;
    display: flex;
    align-items: center;
}
.dash{
    border: solid black;
    width: 1rem;
    border-width: 0.5px;
    margin-right: 0.2rem;
}


.competenciesWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 80rem;
    @media only screen and (max-width: 960px)  {
      margin: 0.3rem;
    }

}



.competenciesContainersWrapper{
    background-color: rgb(252, 254, 255);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: var(--div-radius);
}



@keyframes fade-in {
    0%   { 
    opacity: 0; 	
    }
    100% { 
    opacity: 1;
    }
}