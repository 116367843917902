$line: none;

.competency{
    margin: 0.1rem;
    box-shadow: 0 5px 10px rgb(240 240 240 / 20%);
    border-radius: var(--div-radius);
    border-width: 0.5px;
    text-align: left;
    display: flex;
    flex-direction: column;
    animation-delay: 1s;
    background-color: white;
    padding: 0.7rem;
    animation: fade-in ease-in 0.5s forwards;
    opacity: 0;
    margin-bottom: 1rem;
    animation-delay: 1s;
    color: var(--info-text);
    h2{
        margin: 0;
        margin-bottom: 0.5rem;
        color: black;
    }
    @media only screen and (min-width: 960px)  {
        margin-bottom: 4rem;
        margin-top: 2.5rem;
    }
}

// Skills conatiner
.skillsWrapper{
    border: $line red;
    display: flex;
    flex-wrap: wrap;
}

.skillContainer{
    border: $line green;
}

.skill{
    border: $line blue;
    margin: 1%;
    padding: 1rem;
    border-radius: 2rem;
    background-color:  #ff5b3e;
    opacity: 0.8;
    box-shadow: 0 5px 10px rgb(240 240 240 / 20%);
    color: white;
}


@keyframes fade-in {
    0%   { 
    opacity: 0; 	
    }
    100% { 
    opacity: 1;
    }
}