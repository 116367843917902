.intro{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3rem;
}

.intro > h1{
    font-weight: 900;
    animation: slide-up ease-in 0.5s;
}

.intro > p{
    font-weight: 100;
    color: var(--info-text);
    animation: slide-right ease-in 0.5s forwards;
    animation-delay: 1s;
    opacity: 0;
}

@media only screen and (max-width: 1200px)  {
    .intro > p{
        font-size: 1.5rem;
        line-height: 2.5rem;
        max-width: 40rem;
    }

    .intro > h1{
        font-size: 4rem;
    }
}


@media only screen and (min-width: 1200px)  {
    .intro > p{
        font-size: 2.5rem;
        // max-width: 55rem;
        line-height: 4rem;
    }

    .intro > h1{
        font-size: 8rem;
    }
}


span{
    color: var(--red);
    font-weight: 900;
}


@keyframes slide-up {
    0%   { 
    transform: translateY(100%);
    opacity: 0; 		
    }
    100% { 
    transform: translateY(0%); 
    opacity: 1;
    }
   }

   @keyframes slide-right {
    0%   { 
    transform: translateX(100%);
    opacity: 0; 		
    }
    100% { 
    transform: translateX(0%); 
    opacity: 1;
    }
   }