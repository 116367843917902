.navigation {
  position: fixed;
  top: 0;
  width: 99.6vw;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0.5rem 0;
  background-color: var(--background-color);
  z-index: +4;
}

@media only screen and (max-width: 1200px) {
  .hMenu {
    margin-right: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 2.05rem;
    align-items: center;
    padding: 0.6rem;
    font-size: 2rem;
    color: var(--info-text);
  }
  .lineOneHm,
  .lineTwoHm,
  .lineThreeHm {
    border: solid var(--info-text);
    width: 1.5rem;
    border-radius: 4px;
    border-width: 0.15rem;
  }
  .navSelector {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .navSelector {
    display: block;
  }
  .hMenu {
    display: none;
  }
}

.navSelector h2 {
  color: var(--info-text);
}

.navSelector {
  margin-right: 3rem;
}

.navSelector:hover {
  cursor: pointer;
  animation: hover ease-in 0.2s forwards;
}

.hMenu:hover {
  cursor: pointer;
  animation: hover ease-in 0.2s forwards;
}

// --- LOGO STYLING --- //
.logo {
  width: 4.2rem;
  height: 4rem;
  font-size: 2rem;
  justify-content: space-between;
}

.logo:hover {
  cursor: pointer;
  .line,
  .straightEnd {
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
  .straight {
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
  .circle {
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }
}

.line {
  top: 1.97rem;
  border: solid grey;
  rotate: -50deg;
  left: 2.3rem;
  position: absolute;
  width: 2.5rem;
}
.lineTwo {
  top: 1.97rem;
  border: solid grey;
  rotate: 50deg;
  left: 0.7rem;
  position: absolute;
  width: 2.5rem;
  border-radius: 0.1rem;
}

.straight {
  border: solid black;
  left: 0.8rem;
  top: 2;
  height: 3.5rem;
  border-width: 0.25rem;
  position: absolute;
  z-index: 1;
  border-radius: 0.25rem;
}

.straightEnd {
  border: solid grey;
  left: 4.5rem;
  top: 2.1;
  height: 3.5rem;
  position: absolute;
  border-width: 0.3rem;
  border-radius: 0.5rem;
}

.line,
.lineTwo,
.straightEnd {
  border: solid var(--red);
  opacity: 0.95;
  border-width: 4px;
  border-radius: 0.5rem;
}

.circle {
  border: solid black;
  padding: 0.9rem;
  left: 0.8rem;
  box-shadow: 0.5px 0.5px 0.5px 0.8px rgba(0, 0, 0, 0.3);
  top: 2;
  height: 0.6rem;
  border-radius: 0 50% 50% 0;
  position: absolute;
  border-width: 0.3rem;
  z-index: +1;
}

.hamburgerNav {
  padding: 5rem;
  position: fixed;
  top: 5.5rem;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: +3;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  background-color: var(--red);
  animation: slide-right ease-in 0.3s forwards;
}

.hamburgerNavOut {
  // border: solid purple;
  padding: 5rem;
  position: fixed;
  // position: absolute;
  top: 5.5rem;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: +3;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  background-color: var(--red);
  animation: slide-out ease-in 0.3s forwards;
}

.navItem {
  // border: solid orange;
  margin-right: 10rem;
  display: flex;
  justify-content: center;
}

.navItem h1 {
  color: white;
}

.navItem:hover {
  cursor: pointer;
  animation: fade ease-in 0.2s forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes hover {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
