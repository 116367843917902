@media only screen and (max-width: 960px) {
  .projectSection {
    padding: 0 0.3rem 0 0.3rem;
  }
}

.projectSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  border-bottom: solid var(--outlines);
  border-width: 0.5px;
  width: 100%;
  text-align: left;
  max-width: 80rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  animation: fade-in ease-in 0.5s forwards;
  animation-delay: 1s;
  opacity: 0;
  display: flex;
  align-items: center;
}

.dash {
  border: solid black;
  width: 1rem;
  border-width: 0.5px;
  margin-right: 0.2rem;
}

//Button wrapper styling
.buttonWrapper {
  //   border: solid gray;
  display: flex;
  justify-content: center;
}

// Chevron button styling
.button {
  border: solid var(--red);
  border-width: 0.3rem;
  border-radius: 2.2rem 2.2rem 2.2rem 2.2rem;
  padding: 0.1rem;
  text-align: center;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 2rem;
    width: 2rem;
    color: var(--red);
    margin: 0.2rem;
  }
}

.button:hover {
  cursor: pointer;
  animation: hover ease-in 0.1s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hover {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
